@import '../../Styles/media';

.Collections-Wrapper {
  margin-top: 3rem;
  @include mobile {
    margin-top: 2rem;
  }
  @include tablet {
    margin: 0 20px;
  }

  .Heading-Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;

    .Text-Wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      align-items: center;
      gap: 2rem;
      .Line {
        height: 1px;
        background-color: black;
        width: 100%;
      }
      .Small-Text {
        font-size: 22px;
        letter-spacing: 3px;
        
        @include mobile {
          font-size: 16px;
        }
      }
      .Big-Text {
        font-size: 35px;
        white-space: nowrap;

        @include mobile {
          font-size: 22px;
        }
      }
    }
  }

  .Card-Container {
    display: flex;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    margin-top: 3rem;

    @include tablet {
      display: grid;
      grid-template-columns: 2fr 2fr;
      gap: 1rem;
    }

    @include mobile {
      margin-top: 2rem;
      flex-direction: column;
      gap: 1rem;
    }
    .Collections-Card {
      width: 22.78em;
      text-align: center;
      text-decoration: none;
      color: black;

      @include tablet {
        margin: auto;
      }


      @include mobile {
        width: 20em;
        margin: auto;
      }
      .Image {
        max-width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        cursor: pointer;

        @include mobile {
          height: unset;
          min-height:  320px;
          min-width: 320px;
          background: linear-gradient(
          100deg,
          rgba(255, 255, 255, 0) 40%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 60%
        )
        #ededed;
        }
      }
      .Title {
        padding: 4px 0px;
        font-size: 23px;
        letter-spacing: 2px;
        text-transform: uppercase;
        cursor: pointer;

        @include mobile {
          padding: 2px 0px;
          font-size: 20px;
        }
      }
    }
  }
}
