@import "../../Styles/media";

.Gallery-Container {
  min-height: 100dvh;
  .Base-Gallery {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;

    @include mobile {
      display: grid;
      justify-items: center;
      margin: 0 2rem;
    }

    .Gallery-Wrapper {
      position: relative;
      width: 65%;

      @include mobile {
        width: 100%;

        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (1 / 1) * 100%;
        }

        > img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      @include tablet {
        width: unset;
      }

      .Image {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 0;
        cursor: pointer;

        @include mobile {
          aspect-ratio: 1/1;
        }
      }
      .Name {
        bottom: 10%;
        position: absolute;
        left: 8%;
        cursor: pointer;
        font-size: 2rem;
        color: #fff;
        letter-spacing: 2px;
        text-transform: capitalize;

        @include mobile {
          font-size: 24px;
        }
      }
    }

    .Description-Container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      @include mobile {
        padding-inline: 1rem;
        font-family: Cardo;
        aspect-ratio: 1/1;
      }

      @include tablet {
        padding-inline: 2rem;
      }

      .Title {
        font-size: 30px;
        line-height: 2.35em;
        letter-spacing: 2px;
        text-transform: capitalize;

        @include mobile {
          font-size: 24px;
          line-height: 2em;
        }

        @include mobile {
          font-size: 26px;
        }
      }

      .Description {
        width: 40%;
        font-size: 20px;
        line-height: 2rem;

        @include mobile {
          width: 80%;
          letter-spacing: 0;
          font-family: Cardo;
          font-size: 18px;
        }

        @include tablet {
          width: unset;
          font-size: 18px;
        }
      }
    }
  }

  .Line-Wtapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    margin: 28px 0 28px 0;

    .Line {
      display: flex;
      height: 1px;
      background: #9c9191;
      width: 30%;

      @include mobile {
        width: 36%;
      }
    }

    .Cross {
      color: #666363;
    }
  }

  .Child-Gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: 34px;
    max-width: 1024px;
    margin: 0 auto;

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      margin: 0 2rem;
      gap: 20px;
    }

    @include tablet {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
    }

    .Gallery-Wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 22em;
      text-align: center;
      gap: 14px;
      text-decoration: none;

      @include tablet {
        width: 14em;
      }

      @include mobile {
        width: unset;
        gap: 12px;
      }

      .Image {
        max-width: 100%;
        object-fit: cover;
        cursor: pointer;
        aspect-ratio: 1/1;

        @include mobile {
          aspect-ratio: unset;
        }
      }

      .Name {
        font-size: 20px;
        text-transform: capitalize;
        cursor: pointer;
        color: black;
      }
    }
  }
}
