@mixin ios-device {
    @supports (-webkit-touch-callout: none) {
        @content
    }
}

@mixin big-desktop {
    @media (min-width: 1450px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1025px) {
        @content;
    }
}

@mixin medium-device {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin before-desktop {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 1024px) and (min-width: 768px) {
        @content;
    }
}

@mixin small-tablet {
    @media (max-width: 900px) and (min-width: 768px) {
        @content;
    }
}

@mixin after-mobile {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin medium-mobile {
    @media (max-width: 375px) {
        @content;
    }
}

@mixin small-mobile {
    @media (max-width: 320px) {
        @content;
    }
}

