@import '../../Styles/media';


.Recent-Storise-Container {
  @include tablet {
    padding: 0 20px;
  }
  .Heading-Wrapper {
    text-align: center;
    padding: 4rem 0 3rem;
    @include mobile {
      padding: 2.5rem 0 1.5rem;
    }
    .Heading {
      font-size: 1.8rem;
      letter-spacing: 3px;
      text-transform: uppercase;

      @include mobile {
        font-size: 1.5rem;
      }
    }
  }
  .Card-Container {
    gap: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet {
      gap: 1rem;
    }
    @include mobile {
      flex-direction: column;
    }
    .Story-Card {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      background-color: #243524;
      text-decoration: none;

      @include tablet {
        height: 480px;
      }
      
      @include mobile { 
        width: 100%;

      }

      .Image {
        height: 20.5em;
        max-width: 100%;
        cursor: pointer;
        object-fit: cover;
        // background: whitesmoke;
        @include mobile {
          height: unset;
          min-height:  240px;
          width: 100%;
          background: linear-gradient(
          100deg,
          rgba(255, 255, 255, 0) 40%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 60%
        )
        #ededed;
        }
      }

      .Contend-Wrapper {
        padding: 2rem 0 3rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        text-align: center;
        @include mobile {
          padding: 1rem 0 1.5rem 0;
        }
        .Title {
          font-size: 26px;
          text-transform: uppercase;
          font-family: Cardo;
          letter-spacing: 1px;
          cursor: pointer;
          @include mobile {
            font-size: 22px;
          }
          @include tablet {
            font-size: 22px;
          }
        }

        .Location {
          font-size: 12px;
          font-family: "Jost", sans-serif;
          letter-spacing: 4px;
          cursor: pointer;
          text-transform: capitalize;
        }
      }
    }
  }
}
