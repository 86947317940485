.Home-Container {
  display: block;
  @media (max-width: 1200px) {
    // display: none;
  }
  .Banner-Wrapper {
  }
}

.Mobile-View {
  // display: none;
  @media (max-width: 1200px) {
    display: block;
  }
}
