@import "../../Styles/media";

.Contact-Container {
	background-image: url(../../Shared/map.png);
	background-repeat: no-repeat;
	position: relative;

	@include mobile {
		background-repeat: repeat;
		background-size: contain;
	}

	&::before {
		content: "";
		position: absolute;
		background: #1b1b1b;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0.6;
	}

	.Contact-wrapper {
		position: relative;
		z-index: 2;
		color: #fff;
		max-width: 1200px;
		margin: 0 auto;
		padding-block: 4rem;
		line-height: 1.75rem;

		@include mobile {
			padding: 2rem;
		}

		.header {
			margin-block-end: 2rem;

			h3 {
				font-size: 1.75rem;
				margin-block-end: 0.5rem;
			}

			span {
				font-size: 1rem;
			}
		}

		.content {
			display: grid;
			grid-template-columns: 65% auto;
			gap: 5rem;

			.logo-wrapper {
				display: none;

				img {
					width: 22rem;
				}

				@include after-mobile {
					display: block;
				}
			}

			@include mobile {
				grid-template-columns: 1fr;
				gap: 3rem;
			}

			.Right-Side {
				display: grid;
				height: fit-content;
				gap: 1rem;

				@include after-mobile {
					display: none;
				}

				.Title {
					font-size: 2rem;
				}

				.Social-Media {
					margin-block: 2rem;

					h3 {
						font-size: 2rem;
						margin-block-end: 1rem;
					}

					.icons-wrapper {
						display: flex;
						align-items: center;
						gap: 1rem;

						svg {
							fill: #fff;
						}
					}
				}

				.Details-Wrapper {
					display: flex;
					align-items: center;
					gap: 0.5rem;

					i {
						height: 1.25rem;
					}
				}
			}
		}

		form {
			width: 100%;

			label {
				display: block;
				width: 100%;
				margin-bottom: 8px;
				font-weight: bold;
			}

			input,
			textarea {
				color: #fff;
				width: 100%;
				padding: 10px;
				margin-bottom: 16px;
				border: none;
				font-size: 1rem;
				background: transparent;
				border-bottom: 1px solid #ccc;
				box-sizing: border-box;

				&:focus {
					outline: none;
					background-color: transparent;
				}

				@include mobile {
					padding: 2px;
				}
			}

			.field-group {
				display: flex;
				justify-content: space-between;
				width: 100%;
				gap: 1rem;
			}

			.submit {
				padding: 1rem 3.145rem;
				border: none;
				border-radius: 4px;
				cursor: pointer;
				font-size: 1rem;
				font-weight: bold;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-left: auto;

				@include mobile {
					padding: 0.75rem 3rem;
				}
			}
		}
	}
}