@import "../../Styles/media";

.Footer-Container {
  background-image: url(../../Shared/Footer_Bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  position: relative;
  margin-top: 3rem;
  
  @include mobile{
    display: none;
  }

  &::before {
    background: #1b1b1b;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.8;
  }
  .Footer-Wrapper {
    position: relative;
    z-index: 2;
    color: #999;
    padding: 3rem 0 2rem 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 1290px;

    @include tablet {
      padding-bottom: 1rem;
    }
    .Row {
      display: flex;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      @include mobile {
        flex-direction: column;
      }

      .Title {
        color: #fff;
        font-family: "Jost", sans-serif;
        line-height: 1.25em;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 20px;
        display: inline-block;
        position: relative;
      }
      .Left-Side {
        padding-right: 40px;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;

        @include tablet {
          padding-right: unset;
          width: 30%;
          justify-content: center;
        }

        @include mobile {
          padding-right: unset;
        }
        .Footer-Logo {
          width: 300px;
          margin-bottom: 20px;

          @include tablet {
            width: 200px;
          }

          .Logo-Image {
            max-width: 100%;
          }
        }
      }
      .Center {
        padding: 34px 0;
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid rgba(255, 255, 255, 0.1);

        @include tablet {
          width: 30%;
        }
        @include mobile {
          padding: unset;
          width:  unset;
          align-items: unset;
        }
        .Links-Wrapper {
          padding-left: 2rem;
          li {
            padding-top: 14px;
            font-size: 16px;

            a {
              text-decoration: none;
              color: #999;

              &:hover {
                color: #fff;
                cursor: pointer;
                border-bottom: 1px solid #fff;
                transition: 0.4s;
                width: fit-content;
              }
            }

            &:first-child {
              padding-top: 0;
            }
          }
        }
      }

      .Right-Side {
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        width: 33%;
        margin: 0 auto;
        @include tablet {
          width: 30%;
        }
        
        @include mobile {
          width:  unset;
        }
        
        .Address {
          line-height: 22px;
          text-transform: capitalize;
        }
        .Details-Wrapper {
          display: flex;
          gap: 10px;
          padding-bottom: 6px;
        }
      }
    }

    .Footer-Bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      @include tablet {
        margin-top: 1rem;
        padding: 0 20px;
        align-items: center;
      }

      @include mobile {
        flex-direction: column;
      }
      .Social-Media {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-around;
        flex-shrink: 0;
        transition: width ease 0.5s;
        cursor: pointer;
        padding-bottom: 20px;

        .Icons {
          svg {
            &:hover {
              fill: #fff !important;
              transition: 0.4s;
            }
          }
        }
      }
    }
  }
}

.Copy-Rights-Mobile {
  background-color: #030303fb;
  color: whitesmoke;
  padding-block: .5rem;
  text-align: center;
  letter-spacing: 2px;
  @include after-mobile {
    display: none;
  }
}
