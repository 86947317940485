@import url(./Styles/_base.scss);
@import url(./Styles/_media.scss);

.css-6kluu6 {
    display: none !important;
    font-size: 0 !important;
}

.css-lgbo0i {
    display: none !important;
    font-size: 0 !important;
}

[class="css-6kluu6"] {
    display: none;
}

iframe {
    #document {
        display: none;
    }
}