@import "../../Styles/media";

.GalleryList-Container {
  min-height: 100dvh;
  .Base-Image-Wrapper {
      margin-top: 10px;
    .Image-Container {
      width: 800px;
      margin: auto;

      @include mobile {
        width: 100%;
      }

      @include tablet {
        width: 100%;
      }
      .Image {
        max-width: 100%;
      }
    }
  }

  .Image-Collection-Container {

    .Image-Container {
      width: 800px;
      margin: auto;

      @include mobile {
        width: 100%;
      }

      @include tablet {
        width: 100%;
      }

      .Image {
        max-width: 100%;
      }
    }
  }
}
