@import "../../Styles/media";


.video-player {
  position: relative;
  padding-bottom: 30.25%; /* 16:9 */
  height: 0;
  margin: 3rem 10rem 0rem;

  @include mobile {
    margin: 2rem 0 0 ;
    padding-bottom: 60.25%;
  }
  @include tablet {
    margin: 20px;
  }
}
#player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.html5-video-player{
  background-color: #fff;
}