@import url("https://fonts.googleapis.com/css2?family=Cardo&display=swap");


* {
  padding: 0;
  margin: 0;
}

body {
  font-family: Cardo;
  -webkit-font-smoothing: antialiased;
  background: #fff;

  &:has(.Contact-Container) {
    .Footer-Container {
      margin-top: 0;
    }
  }
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20%;
  background-color: rgb(83, 83, 83);
}

::-webkit-scrollbar-track {
  border-radius: 20%;
  background-color: rgb(212, 209, 209);
}
